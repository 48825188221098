<!--
 * @Author: yhm 1913238521@qq.com
 * @Date: 2023-11-13 16:20:48
 * @LastEditors: yhm 1913238521@qq.com
 * @LastEditTime: 2023-12-21 14:51:35
 * @FilePath: \nestjs-demoe:\project\vue-template\vue-material-dashboard-master\src\pages\household\list.vue
 * @Description: 
-->
<template>
	<div class="user">
		<div class="md-layout" v-loading="loading">
			<div class="search-container head-shadow">
				<div class="search-title">查询信息</div>
				<!-- <div class="search-people">
					<div class="search-form">
						<span class="search-form-text search-form-title md-title">找 人</span><el-input class="search-input"
							 v-model="form.name" placeholder="请输入姓名"></el-input><span
							class="search-form-text">或</span>
						<el-input class="search-input"  v-model="form.sfz"
							placeholder="请输入身份证号码"></el-input><span class="search-form-text">或</span>
						<el-input class="search-input"  v-model="form.phone"
							placeholder="请输入手机号"></el-input><span class="search-form-text">或</span>
						<el-input class="search-input"  v-model="form.licensePlate"
							placeholder="请输入车牌号"></el-input>
					</div>
					<div class="search-btns">
						<md-button class="query-btn md-success">
							<i class="el-icon-zoom-in el-icon"></i>
							查 询
						</md-button>
						<md-button class="rest-btn">
							<i class="el-icon-refresh-right el-icon"></i>
							重 置
						</md-button>
					</div>
				</div> -->

				<div class="search-people search-household mt-10">
					<div class="search-form">
						<span class="search-form-text search-form-title mr-15">找 户</span>
						<el-select class="search-household-input" v-model="searchParmas.area" placeholder="请选择小区" clearable>
							<!-- <el-select v-model="userForm.area" placeholder="请选择小区"> -->
							<el-option v-for="item in areaList" :key="item.code" :value="item.code"
								:label="item.name"></el-option>
							<!-- </el-select> -->
						</el-select>
						<el-input class="search-input search-household-input" v-model="searchParmas.bding"
							placeholder="请输入楼幢">
							<!-- <span slot="suffix">幢</span> -->
						</el-input>
						<el-input class="search-input search-household-input" v-model="searchParmas.unit"
							placeholder="请输入单元号">
							<!-- <span slot="suffix">单元</span> -->
						</el-input>
						<el-input class="search-input search-household-input" v-model="searchParmas.hnum"
							placeholder="请输入房间号">
							<!-- <span slot="suffix">房间</span> -->
						</el-input>
						<div class="mt-15">
							<span class="search-form-text search-form-title mr-5">户性质</span>
							<el-select class="" v-model="searchParmas.hpropty" placeholder="请选择户性质" clearable>
								<el-option v-for="item in hproptyList" :key="item.value" :value="item.value"
									:label="item.label"></el-option>
							</el-select>
							<span class="search-form-text search-form-title">自定义标签</span>
							<el-select class="" v-model="searchParmas.tagCode" placeholder="请选择自定义标签" clearable>
								<el-option v-for="item in tags" :key="item.code" :value="item.code"
									:label="item.name"></el-option>
							</el-select>
						</div>
					</div>
					<div class="search-btns">
						<div>
							<md-button class="query-btn md-success" @click="searchList">
								<i class="el-icon-zoom-in el-icon"></i>
								查 询
							</md-button>
						</div>
						<div>
							<md-button class="rest-btn md-default" @click="clearSearch">
								<i class="el-icon-refresh-right el-icon"></i>
								重 置
							</md-button>
						</div>
					</div>
				</div>
			</div>

			<div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100 mt-20">
				<md-card class="md-card-plain">
					<md-card-header class="card-header" md-alignment="center">
						<!-- <h4 class="title">查询结果</h4> -->
						<!-- <p class="category"> -->
						<md-button class="md-success" @click="addUser"><i class="el-icon-plus mr-5"></i>添加户</md-button>
						<!-- </p> -->
					</md-card-header>
					<md-card-content class="card-content">
						<!-- <el-table :data="tableData">
							<el-table-column type="index" align="center" label="编号" width="100">
							</el-table-column>
							<el-table-column prop="nickName" label="姓名" min-width="120">
							</el-table-column>
							<el-table-column prop="phone" label="住址" min-width="120">
								<template slot-scope="scope">
									{{ scope.row.phone || '-' }}
								</template>
							</el-table-column>
							<el-table-column prop="nickName" label="性别" min-width="120">
							</el-table-column>
							<el-table-column prop="mobile" label="手机号码" min-width="120">
								<template slot-scope="scope">
									{{ scope.row.mobile || '-' }}
								</template>
							</el-table-column>
							<el-table-column prop="createTime" label="户主关系" min-width="120">
								<template slot-scope="scope">{{ formatDate(scope.row.createTime) }}</template>
							</el-table-column>
							<el-table-column prop="loginTime" label="居住状态" min-width="120">
								<template slot-scope="scope">{{ formatDate(scope.row.loginTime) }}</template>
							</el-table-column>
							<el-table-column prop="phone" label="人员类型" min-width="120">
								<template slot-scope="scope">
									<el-switch @change="(v) => changeStatus(v, scope.row)" v-model="scope.row.status"
										:active-value="1" :inactive-value="0" active-color="#13ce66"></el-switch>
								</template>
							</el-table-column>
							<el-table-column fixed="right" align="center" label="操作" width="240">
								<template slot-scope="scope">
									<el-button type="text"  @click="editUser(scope.row)">
										编辑
									</el-button>
									<el-popconfirm @confirm="delUser(scope.row)" confirm-button-text='确认'
										cancel-button-text='取消' icon="el-icon-info" icon-color="red"
										:title="`确认永久删除 ${scope.row.nickName} 吗？`">
										<el-button slot="reference" class="del-user" type="text" >删除</el-button>
									</el-popconfirm>
								</template>
							</el-table-column>
						</el-table> -->
						<el-table :data="tableData">
							<el-table-column type="index" align="center" label="序号" width="100">
							</el-table-column>
							<el-table-column prop="address" label="户地址" min-width="120">
							</el-table-column>
							<el-table-column prop="hpropty" label="户性质" min-width="120">
								<template slot-scope="scope">
									<div v-if="scope.row.hpropty">{{ hproptyList[scope.row.hpropty - 1].label || '-' }}
									</div>
									<div v-else>-</div>
								</template>
							</el-table-column>
							<el-table-column prop="tags" label="自定义标签" min-width="120">
								<template slot-scope="scope">
									<template v-if="scope.row.tags && scope.row.tags.length">
										<template v-for="(item, index) in tags">
											<el-tag class="mr-5"
												v-if="scope.row.tags && scope.row.tags.indexOf(item.code) > -1"
												:key="item.code" effect="plain">
												{{ item.name }}
											</el-tag>
										</template>
									</template>
									<div v-else>-</div>
									<!-- <el-checkbox-group :value="scope.row.tags"> -->
									<!-- <el-checkbox v-if="scope.row.tags[index] == item.code" :label="item.name" :value="true"
										v-for="(item, index) in tags" :key="item.code" :checked="true" border></el-checkbox> -->
									<!-- </el-checkbox-group> -->
								</template>
							</el-table-column>
							<el-table-column prop="hremark" label="户备注" min-width="120">
								<template slot-scope="scope">
									{{ scope.row.hremark || '-' }}
								</template>
							</el-table-column>

							<el-table-column fixed="right" align="center" label="操作" width="240">
								<template slot-scope="scope">
									<el-button type="text" @click="peopleAdmin(scope.row)">
										人员管理
									</el-button>
									<el-button type="text" @click="editUser(scope.row)">
										编辑
									</el-button>
									<el-popconfirm @confirm="delHouse(scope.row)" confirm-button-text='确认'
										cancel-button-text='取消' icon="el-icon-info" icon-color="red"
										:title="`确认永久删除 ${scope.row.address} 吗？`">
										<el-button slot="reference" class="del-user" type="text">删除</el-button>
									</el-popconfirm>
								</template>
							</el-table-column>
						</el-table>
						<el-pagination class="page" layout="total, prev, pager, next" :current-page="page.pageNum"
							@current-change="updatePagination" :page-size="page.pageSize" :total="page.total">
						</el-pagination>
						<!-- <pagination ref="pagination" :total="page.total" @nextPage="updatePagination" /> -->
						<!-- <el-pagination class="page" layout="total, prev, pager, next" :current-page="1"
							@current-change="updatePagination" :page-size="12" :total="1000">
						</el-pagination> -->
					</md-card-content>
				</md-card>
			</div>
		</div>

		<!-- 添加编辑用户 -->
		<el-dialog :title="dialogFormTitle" :visible.sync="dialogVisible" width="600px" :before-close="handleCloseForm">
			<el-form ref="dialogForm" :rules="formRules" :label-position="labelPosition" label-width="100px"
				:model="userForm">
				<el-form-item label="小区/门牌" prop="area">
					<el-select v-model="userForm.area" placeholder="请选择小区">
						<el-option v-for="item in areaList" :key="item.code" :value="item.code"
							:label="item.name"></el-option>
					</el-select>
					<!-- <el-input v-model="userForm.area" placeholder="请输入用户名"></el-input> -->
				</el-form-item>
				<el-form-item label="地址" prop="bding">
					<el-input style="max-width: 100px" v-model="userForm.bding" placeholder="请输入">
						<span slot="suffix">幢</span>
					</el-input>
					<el-input style="max-width: 100px" class="ml-15" v-model="userForm.unit" placeholder="请输入">
						<span slot="suffix">单元</span></el-input>
					<el-input style="max-width: 120px" class="ml-15" v-model="userForm.hnum" placeholder="请输入">
						<span slot="suffix">房间</span>
					</el-input>
				</el-form-item>
				<el-form-item label="性质" prop="hpropty">
					<el-select v-model="userForm.hpropty" placeholder="请选择性质">
						<el-option v-for="item in hproptyList" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<!-- <el-form-item label="类型" prop="type">
					<el-select v-model="userForm.type" placeholder="请选择类型">
						<el-option label="普通住房" :value="1"></el-option>
						<el-option label="写字楼" :value="2"></el-option>
					</el-select>
				</el-form-item> -->
				<el-form-item label="自定义标签">
					<el-checkbox-group v-model="userForm.tags">
						<el-checkbox :label="item.code" :value="item.code" v-for="item in tags" :key="item.code">{{
							item.name }}</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
				<el-form-item label="业主信息" prop="yz">
					<el-form-item label="" v-if="userForm.buyers && userForm.buyers.length">
						<el-form v-for="(item, index) in userForm.buyers" :ref="'ownerForm-' + index" label-width="80px"
							class="owner-form">
							<span class="el-icon-circle-close close" @click="delBuyers(index)"></span>
							<el-form-item label="姓名" prop="userForm">
								<el-input v-model="item.name" placeholder="请输入姓名"></el-input>
							</el-form-item>
							<el-form-item label="联系电话" prop="userForm" class="mt-20">
								<el-input v-model="item.phone" placeholder="请输入联系电话"></el-input>
							</el-form-item>
							<el-form-item label="备注" prop="userForm" class="mt-20">
								<el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="请输入备注"
									v-model="item.remark">
								</el-input>
							</el-form-item>
						</el-form>
					</el-form-item>
					<md-button class="md-success md-block" @click="addBuyers">添加业主</md-button>
				</el-form-item>

				<el-form-item label="备注">
					<el-input type="textarea" :autosize="{ minRows: 3, maxRows: 5 }" placeholder="请输入内容"
						v-model="userForm.hremark">
					</el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="handleCloseForm">取 消</el-button>
				<el-button v-loading="btnLoading" type="success" @click="subUser">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
// import { SimpleTable, OrderedTable } from "@/components";
import * as API from '@/api/peopleResult.js'
// import Pagination from '@/components/pagination/index.vue'
import { formatDate } from '@/utils/assist.js'
export default {
	components: {
		// Pagination
	},
	data () {
		return {
			// 1=常住户籍人口房2=常住非户籍人口房3=户籍在人不在（出租房）4=人户不在（出租房） 5=户籍在人不在（群租房）6=人户不在（群租房）7=户籍在人不在（空置房）8=人户不在（空置房）
			hproptyList: '常住户籍人口房=常住非户籍人口房=户籍在人不在（出租房）=人户不在（出租房）=户籍在人不在（群租房）=人户不在（群租房）=户籍在人不在（空置房）=人户不在（空置房）=商铺'.split('=').map((v, i) => {
				return {
					label: v,
					value: i + 1
				}
			}),
			tags: [],
			roleInfo: [],
			areaList: [],
			form: {},
			ownerForm: [],
			formRules: {
				area: [
					{
						required: true,
						message: '请选择小区',
						trigger: 'change'
					},
					// {
					// 	required: true,
					// 	message: '请输入3到30位字符',
					// 	min: 3,
					// 	max: 30,
					// 	trigger: 'blur'
					// }
				],
				bding: [{
					required: true,
					message: '请输入楼幢',
					trigger: 'blur'
				}],
				hpropty: [
					{
						required: true,
						message: '请选择性质',
						trigger: 'change'
					},
				],
				// type: [
				// 	{
				// 		required: true,
				// 		message: '请选择类型',
				// 		trigger: 'change'
				// 	},
				// ]
				// tags: [
				// 	{
				// 		required: true,
				// 		message: '请选择标签',
				// 		trigger: 'change'
				// 	}
				// ]
			},
			labelPosition: 'rigin',
			userForm: {
				area: '',
				bding: '',
				tags: [],
				buyers: [],
				unit: '',
				hpropty: '',
				hnum: '',
				hremark: '',
				// type: ''
			},
			searchParmas: {
				area: '',
				bding: '',
				hnum: '',
				unit: '',
				hpropty: '',
				tagCode: ''
			},
			dialogVisible: false,
			roleDialogVisible: false,
			dialogFormTitle: '户信息添加',
			tableHeaderColor: '',
			tableData: [],
			paginatedUsers: [],
			loading: false,
			btnLoading: false,
			page: {
				total: 0,
				pageNum: 1,
				pageSize: 10
			},
			roleList: [],
			currentUserId: '',
			workLimitList: []
		}
	},
	created () {
		const query = this.$route.query
		Object.keys(this.searchParmas).forEach(key => {
			if (query[key] != '' || query[key] != undefined) {
				this.searchParmas[key] = query[key]
			}
		})
		this.getHouseList()
		this.getTags()
		this.getAreaList()
	},
	methods: {
		/**
		 * 获取列表
		 */
		async getHouseList () {
			this.loading = true
			try {
				const { pageSize, pageNum } = this.page
				const data = await API.getHouseList({
					pageNum,
					pageSize,
					...this.searchParmas
				})
				if (data.code === 200 && data.data) {
					this.tableData = data.data.list || []
					this.page.total = data.data.total
				}
				this.loading = false
			} catch (error) {
				this.loading = false
				console.error(error)
			}
		},
		/**
		 * 获取标签
		 */
		async getTags () {
			try {
				const data = await API.getTags()

				if (data.code === 200) {
					this.tags = data.data || []
				}
			} catch (error) {
				console.error(error)
			}
		},
		async getAreaList () {
			const data = await API.getAreaList()

			if (data.code === 200) {
				this.areaList = data.data || []
			}
		},
		/**
		 * 修改用户状态
		 */
		async changeStatus (val, { id, nickName }) {
			try {
				const data = await API.updateStatus({ status: val, id })
				if (data.code === 200) {
					const message = `已成功${val === 1 ? '启用' : '关闭'} ${nickName} 用户！`
					this.$notify.success({
						title: '提示',
						message
					})
				}
			} catch (error) {
				console.error(error)
			}
		},
		/**
		 * 删除用户账号
		 */
		async delHouse ({ id, address }) {
			try {
				const data = await API.delHouse({ id })

				if (data.code === 200) {
					this.$notify.success({
						message: `已成功删除 ${address} 户！`,
						title: '提示'
					})
					this.page.pageNum = 1
					this.getHouseList()
				}
			} catch (error) {
				console.error('delHouse: ', error)
			}
		},
		/**
		 * 获取角色集合
		 */
		addBuyers () {
			this.userForm.buyers.push({
				name: '',
				remark: '',
				phone: ''
			})
		},
		delBuyers (index) {
			this.userForm.buyers.splice(index, 1)
		},
		async addHouse () {
			try {
				this.btnLoading = true
				const data = await API.addHouse({
					...this.userForm
				})
				if (data.code === 200) {
					this.handleCloseForm()
					this.page.pageNum = 1
					this.getHouseList()
					this.$notify.success({
						title: '提示',
						message: '添加成功'
					})
				}
				this.btnLoading = false
			} catch (error) {
				this.btnLoading = false
				console.error(error)
			}
		},
		async updateHouse () {
			try {
				this.btnLoading = true
				const data = await API.updateHouse({
					...this.userForm,
					id: this.currentUserId,
				})
				if (data.code === 200) {
					this.handleCloseForm()
					this.page.pageNum = 1
					this.getHouseList()
					this.$notify.success({
						title: '提示',
						message: '修改成功'
					})
				}
				this.btnLoading = false
			} catch (error) {
				this.btnLoading = false
				console.error(error)
			}
		},
		/**
		 * 获取指定用户角色
		 */
		async getUserRole () {
			try {
				const data = await API.getUserRole({
					id: this.currentUserId
				})
				if (data.code === 200 && data.data) {
					this.roleInfo = data.data.map(t => t.id)
				}
			} catch (error) {
				console.error(error)
			}
		},
		async getHouseInfo () {
			try {
				const data = await API.getHouseInfo({
					id: this.currentUserId
				})
				if (data.code === 200 && data.data) {
					// this.roleInfo = data.data.map(t => t.id)
					const info = data.data
					this.userForm = info
					// Object.keys(this.userForm).forEach(key => {
					// 	if (info[key]) {
					// 		this.userForm[key] = info[key]
					// 	}
					// })
					// this.userForm.workLimits = link && link.map(v => v.id) || []
				}
			} catch (error) {
				console.error(error)
			}
		},
		subUser () {
			this.$refs.dialogForm.validate(validator => {
				if (!validator) return
				if (this.dialogFormTitle === '户信息添加') {
					this.addHouse()
				} else {
					this.updateHouse()
				}
			})
		},
		// handleClose () { },
		addUser () {
			this.dialogFormTitle = '户信息添加'
			this.dialogVisible = true
		},
		editUser ({ id }) {
			this.currentUserId = id
			this.dialogFormTitle = '户信息编辑'
			this.getHouseInfo()
			this.dialogVisible = true
		},
		peopleAdmin ({ id }) {
			this.$router.push({
				path: '/household',
				query: {
					hid: id
				}
			})
		},
		searchList () {
			this.page.pageNum = 1
			this.getHouseList()
		},
		clearSearch () {
			this.searchParmas = {
				area: '',
				bding: '',
				hnum: '',
				unit: ''
			}
			this.searchList()
		},
		/**
		 * 分页翻动
		 */
		updatePagination (page, pageSize, sort, sortOrder) {
			console.log('pagination has updated', page, pageSize, sort, sortOrder);
			this.page.pageNum = page
			this.getHouseList()
		},
		formatDate (dateStr) {
			if (!dateStr) return '-'
			return formatDate(new Date(dateStr).getTime(), 'YY-MM-DD hh:mm')
		},
		handleCloseForm () {
			this.currentUserId = ''
			this.userForm = {
				area: '',
				bding: '',
				tags: [],
				buyers: [],
				unit: '',
				hpropty: '',
				hnum: '',
				hremark: '',
				// type: ''
			}
			this.$refs.dialogForm.resetFields()
			this.dialogVisible = false
		}
	},
}
</script>

<style lang="scss" scoped>
.card-content {
	background-color: #fff;
}

.auth-select {
	width: 100%;
}

.page {
	margin-top: 20px;
	text-align: right;
}

.del-user {
	padding-left: 12px;
}

.card-header {
	text-align: right;
	background-color: #eee;
}

.search-container {
	margin: 0px 15px 0;
	padding: 15px 20px 10px;
	width: 100%;
	border-radius: 2px;
	// background: linear-gradient(180deg, #43D49C 3%, #14A874 99%);
	background-color: #fff;
	font-size: 16px;

	.search-people {
		display: flex;
		// padding: 10px 15px 0;

		.search-form {
			// display: flex;
			align-items: center;
			// flex: 1;
			color: #000;

			&-text {
				padding: 0 15px;
				min-width: 40px;
			}

			&-title {
				min-width: 80px;
			}
		}
	}

	// .search-household {
	// 	padding: 10px 15px;
	// }

	.search-btns {
		// margin-left: 15px;
		// margin-right: 2%;
		// font-size: 18px;
		// text-align: right;
		margin-left: 5%;
		padding-left: 3%;
		border-left: 1px solid #E5E6EB;

		.rest-btn {
			margin: 12px 0 0 0px;
		}

		.query-btn {
			margin: 0;
			// text-align: right;
		}

		.el-icon {
			margin-right: 8px;
		}

		::v-deep .md-button .md-button-content {
			font-size: 16px;
		}
	}

	.search-household-input {
		margin-right: 24px;
	}

	.search-input {
		max-width: 120px;
		// height: 32px;

		// ::v-deep .el-input__inner {
		// 	height: 32px;
		// 	line-height: 32px;
		// }
	}
}

.md-block {
	margin: 0 !important;
}

.owner-form {
	position: relative;
	margin-bottom: 10px;
	padding: 30px 30px 20px 15px;
	border-radius: 2px;
	border: 1px solid #e6e6e6;

	.close {
		position: absolute;
		right: 5px;
		top: 5px;
		padding: 5px;

		&:hover {
			cursor: pointer;
			opacity: .7;
		}
	}
}

.search-title {
	padding-left: 8px;
	border-left: 3px solid #36CC86;
	font-family: Source Han Sans CN;
	font-size: 20px;
	font-weight: 500;
}
</style>